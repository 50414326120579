
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const setting = namespace("setting");
const base = namespace("base");
const app = namespace("app");
@Component({})
export default class Permission extends Vue {
  @base.Action getDictionaryList;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @setting.Action createStoreType;
  storeTypeList: any = [];
  showCreateDialog = false;
  typeFormData = {
    storeType: "",
    storeTypeName: ""
  };
  created() {
    this.loadListData();
  }
  loadListData() {
    return this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
  }
  handleCreateStoreType() {
    this.createStoreType(this.typeFormData).then(data => {
      this.$message.success("创建成功");
      this.showCreateDialog = false;
      this.loadListData();
    });
  }
}
